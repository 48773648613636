import type { TwoFactorStatus } from '@prisma/client'
import type { Plans } from 'modules/business/defs'
import { z } from 'zod'

export const jwtPayload = z.object({
  sub: z.string(),
  '2fa': z.enum(['disabled', 'enabled', 'verified'] as const),
  jti: z.string(),
  exp: z.number().int().positive()
})

export type JWTPayload = z.TypeOf<typeof jwtPayload>

export interface UserAuthentication {
  userID: string
  tokenID: string
  plan?: Plans
  twoFactorStatus: TwoFactorStatus
  sessionExpiresAt: Date
}

/**
 * Map JWT fields to more developer-friendly names and data types.
 */
export function jwtPayloadToUserAuthentication(
  payload: JWTPayload
): UserAuthentication {
  return {
    userID: payload.sub,
    tokenID: payload.jti,
    twoFactorStatus: payload['2fa'],
    sessionExpiresAt: new Date(payload.exp * 1000) // exp is a Unix timestamp
  }
}
