import { base64toUTF8 } from '@47ng/codec'
import { CookieNames } from 'modules/auth/cookies'
import { jwtPayload, jwtPayloadToUserAuthentication } from 'modules/auth/jwt'
import { isServer } from 'modules/std/ssr'

const jwtCookieRegex = new RegExp(`${CookieNames.jwt}=([\\w-]+).([\\w-]+)`)

/**
 * Read the JWT cookie client-side and
 * extract user authentication data from it.
 *
 * Returns a discriminated union to handle SSR.
 */
export function getUserAuthentication() {
  if (isServer) {
    return {
      isServer: true,
      claims: null
    } as const
  }
  try {
    const matches = document.cookie.match(jwtCookieRegex)
    if (!matches || matches.length !== 3) {
      return {
        isServer: false,
        claims: null
      } as const
    }
    const payloadJSON = base64toUTF8(matches[2])
    const payload = jwtPayload.parse(JSON.parse(payloadJSON))
    return {
      isServer: false,
      claims: jwtPayloadToUserAuthentication(payload)
    } as const
  } catch (error) {
    console.error(error)
  }
  return {
    isServer: false,
    claims: null
  } as const
}
