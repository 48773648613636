import { Box, BoxProps, forwardRef, useColorModeValue } from '@chakra-ui/react'
import { AnimatedLogo } from 'modules/ui/logos/animatedLogo'
import React from 'react'

export interface AuthCardLayoutProps extends BoxProps {
  w?: string
}

export const AuthCardLayout = forwardRef<AuthCardLayoutProps, 'main'>(
  ({ w = 'md', children, ...props }, ref) => {
    return (
      <Box
        as="main"
        w={{
          base: '100%',
          sm: w
        }}
        h={{
          base: '100vh',
          sm: 'auto'
        }}
        mx="auto"
        mt={{ base: 0, sm: 32 }}
        minHeight="30vh"
        backgroundColor={useColorModeValue('white', 'gray.800')}
        rounded={{ base: 'none', sm: 'md' }}
        shadow={useColorModeValue('md', 'xl')}
        overflow="auto"
        ref={ref}
        {...props}
      >
        <Box
          as="header"
          pt={6}
          pb={5}
          backgroundColor={useColorModeValue('gray.50', 'gray.700')}
          borderBottomColor={useColorModeValue('gray.300', 'gray.600')}
          borderBottomWidth="1px"
        >
          <AnimatedLogo mx="auto" />
        </Box>
        <Box p={4}>{children}</Box>
      </Box>
    )
  }
)
