import { isServer } from 'modules/std/ssr'
import { queryTypes, useQueryStates } from 'next-usequerystate'
import { useRouter } from 'next/router'
import React from 'react'

/**
 * Reactive extraction of URL query string redirection params
 */
function useRedirectionProps() {
  return useQueryStates({
    redirectTo: queryTypes.string,
    redirectWith: queryTypes.string
  })[0]
}

/**
 * Return a URL to targetPath, or to the `?redirectTo=` query if present.
 */
function useRedirectionURL(targetPath: string) {
  const { redirectTo, redirectWith } = useRedirectionProps()
  const path = redirectTo ?? targetPath
  const origin = isServer ? process.env.APP_URL : window.location.origin
  const url = new URL(path, origin)
  if (redirectTo && redirectWith) {
    // Only inject redirectWith if redirectTo is used
    url.search = redirectWith
  }
  return url
}

/**
 * @returns a callback to apply the redirection URL
 */
export function useRedirect(targetPath: string) {
  const router = useRouter()
  const target = useRedirectionURL(targetPath)
  return React.useCallback(() => router.push(target), [router, target])
}

/**
 * Return a URL to the given path, but with the current query string injected.
 */
export function keepQueryStringTo(path: string) {
  const origin = isServer ? process.env.APP_URL : window.location.origin
  const url = new URL(path, origin)
  url.search = isServer ? '' : window.location.search
  return url
}
