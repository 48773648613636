import React from 'react'
import SessionKeystore from 'session-keystore'

export const keystoreKeys = ['credentials', 'keychainKey'] as const
export type KeystoreKeys = typeof keystoreKeys[number]

export const keystore = new SessionKeystore<KeystoreKeys>({
  name: 'chiffre-client'
})

if (process.env.NODE_ENV === 'development') {
  keystore.on('created', ({ name }) => {
    console.debug(`[keystore] ✨ Key created: ${name}: ${keystore.get(name)}`)
  })
  keystore.on('updated', ({ name }) => {
    console.debug(`[keystore] ♻️ Key updated: ${name}: ${keystore.get(name)}`)
  })
  keystore.on('deleted', ({ name }) => {
    console.debug(`[keystore] 🗑 Key deleted: ${name}`)
  })
  keystore.on('expired', ({ name }) => {
    console.debug(`[keystore] ⏰ Key expired: ${name}`)
  })
}

// --

export function useKeystore() {
  const [_, refresh] = React.useState(0)
  React.useEffect(() => {
    const onChange = () => refresh(count => count + 1)
    keystore.on('created', onChange)
    keystore.on('expired', onChange)
    keystore.on('deleted', onChange)
    keystore.on('updated', onChange)
    return () => {
      keystore.off('created', onChange)
      keystore.off('expired', onChange)
      keystore.off('deleted', onChange)
      keystore.off('updated', onChange)
    }
  }, [])
  return keystore
}
