import { OutgoingLink } from '@47ng/chakra-next'
import { RFC7807Error } from 'modules/errors/rfc7807'
import React from 'react'
import { UseCustomToastOptions, useToast } from './toast'

export function useErrorToast(toastOptions: UseCustomToastOptions = {}) {
  const toast = useToast({
    status: 'error',
    ...toastOptions
  })
  return React.useCallback(
    (error: unknown) => {
      console.error(error)
      if (error instanceof RFC7807Error) {
        toast({
          title: error.rfc7807.title,
          description: error.rfc7807.detail,
          props: {
            children: error.rfc7807.type !== 'about:blank' && (
              <OutgoingLink
                href={error.rfc7807.type}
                fontSize="xs"
                textDecoration="underline"
              >
                More information
              </OutgoingLink>
            )
          }
        })
      } else if (error instanceof Error) {
        toast({
          title: error.name,
          description: error.message
        })
      } else {
        toast({
          title: 'Unknown Error',
          description: String(error)
        })
      }
    },
    [toast]
  )
}
