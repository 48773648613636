import axios, { AxiosRequestConfig } from 'axios'
import type { RouteGenericInterface } from 'fastify/types/route'

type AxiosConfigWithQuery<Data, Query> = Omit<
  AxiosRequestConfig<Data>,
  'params'
> & {
  params?: Query
}

export const versionHeader = {
  'x-chiffre-client-version': process.env.NEXT_PUBLIC_RELEASE_TAG!
}

/**
 * Wrap Axios HTTP methods to make them follow Fastify's generic route traits.
 * This allows using the same type definitions from the routes in the client.
 */
export const api = {
  client: axios.create({
    baseURL: '/api',
    headers: {
      accept: 'application/json',
      ...versionHeader
    },
    timeout: 30_000 // 30 seconds
  }),

  async get<Traits extends RouteGenericInterface>(
    url: string,
    config?: AxiosConfigWithQuery<Traits['Body'], Traits['Querystring']>
  ) {
    return api.client.get<Traits['Reply']>(url, config)
  },

  async post<Traits extends RouteGenericInterface>(
    url: string,
    data: Traits['Body'],
    config?: AxiosConfigWithQuery<Traits['Body'], Traits['Querystring']>
  ) {
    return api.client.post<Traits['Reply']>(url, data, config)
  },

  async delete<Traits extends RouteGenericInterface>(
    url: string,
    config?: AxiosConfigWithQuery<Traits['Body'], Traits['Querystring']>
  ) {
    return api.client.delete<Traits['Reply']>(url, config)
  }
}
